<template>
  <div :class="['out-wrapper']">
    <div class="nav-wrapper">
      <div class="left-wrapper">
        <div class="logo-wrapper"
             @click="goHome">
          <img src="@/assets/logo/logo.png"
               alt="" />
        </div>
        <div class="logo-wrapper-mobile"
             @click="goHome">
          <img src="@/assets/logo/logo.png"
               alt="" />
        </div>
        <div class="menu-wrapper">
          <div class="menu-nav">
            <div class="menu-item"
                 :class="menu.url == $route.path ? 'selected' : ''"
                 v-for="(menu, index) in topMenus"
                 :key="index">
              <router-link :to="menu.url">
                <i class="iconfont"
                   v-if="menu.icon">{{ menu.icon }}</i>
                <span>{{ menu.title }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div :class="['nav-right-box']">
        <div class="switch-lang flex center">
          <span v-if="language">{{ language.toUpperCase() }}</span>
          <div class="lang-panel">
            <div class="lang-title">
              {{ $t("pageHeader.title.switchLang") }}
            </div>
            <div :class="['lang-item', language == 'en' ? 'active' : '']"
                 @click="handleChaneLanguage('en')">
              <span>English</span>
            </div>
            <div :class="['lang-item', language == 'zh' ? 'active' : '']"
                 @click="handleChaneLanguage('zh')">
              <span>简体中文</span>
            </div>
          </div>
        </div>
        <!-- <button @click="sign"> sign</button> -->
        <div :class="['connect-wallet', isHome ? 'no_visiabled' : '']"
             v-if="!userInfo.address">
          <span class="connect-btn"
                @click="openModal"> Connect Wallet</span>
        </div>
        <div :class="['login-info', isHome ? 'no_visiabled' : '']"
             v-else>
          <div class="ops-item center">
            <img src="@/assets/imgs/header/Wallet.svg"
                 alt="" />
            <span class="address">{{ userInfo.showAddress }}</span>
          </div>
          <div class="login-panel">
            <div class="login-title">
              {{ $t("pageHeader.title.loginPanel") }}
            </div>
            <div :class="['login-item']"
                 @click="gotoRouter('/user/center')">
              <span>{{ $t("pageHeader.title.userCenter") }}</span>
            </div>
            <div :class="['login-item']"
                 @click="gotoRouter('/user/orders/orders')">
              <span>{{ $t("pageHeader.title.myOrders") }}</span>
            </div>
            <div :class="['login-item']"
                 @click="gotoRouter('/user/daos')">
              <span>{{ $t("pageHeader.title.myDaos") }}</span>
            </div>
            <div :class="['login-item']"
                 @click="showUserInfo()">
              <span>{{ $t("pageHeader.title.walletInfo") }}</span>
            </div>
            <div :class="['login-item']"
                 @click="logout()">
              <span>{{ $t("pageHeader.title.logout") }}</span>
            </div>
          </div>
        </div>
        <div class="mobile-menu-wrapper">
          <div @click="drawer = true"
               class="icon-font">
            <img src="@/assets/imgs/header/menu_white.svg"
                 alt="" />
          </div>
        </div>
      </div>
    </div>
    <el-drawer title=""
               :visible.sync="drawer"
               size="40%"
               :with-header="false"
               :append-to-body="true"
               :modal-append-to-body="false">
      <div class="menu-drawer">
        <div class="mobile-menu-box">
          <router-link :to="menu.url"
                       :class="['m-menu-item', menu.url == $route.path ? 'active' : '']"
                       v-for="(menu, index) in filteredMenus"
                       :key="index">
            <span>{{ menu.title }}</span>
          </router-link>
        </div>
        <div class="bottom-wrapper">
          <span class="ops-item pc-show"
                v-if="userInfo.address"
                @click="gotoRouter('/user/center')">{{ $t("pageHeader.title.userCenter") }}</span>
          <span class="ops-item pc-show"
                v-if="userInfo.address"
                @click="gotoRouter('/user/orders/orders')">{{ $t("pageHeader.title.myOrders") }}</span>
          <span class="ops-item pc-show"
                v-if="userInfo.address"
                @click="gotoRouter('/user/daos')">{{ $t("pageHeader.title.myDaos") }}</span>
          <span class="ops-item pc-show"
                v-if="userInfo.address"
                @click="showUserInfo()">
            {{ $t("pageHeader.title.walletInfo") }}</span>
          <span class="ops-item pc-show"
                v-if="userInfo.address"
                @click="logout()">
            {{ $t("pageHeader.title.logout") }}</span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  useWeb3ModalTheme,
  useDisconnect,
} from "@web3modal/ethers/vue";
import networks from "@/assets/json/networks";
export default {
  name: "HeaderNav",
  data () {
    return {
      langPanelVisible: false,
      drawer: false,
      localLogin: false,
      topMenus: [
        {
          title: this.$t("pageHeader.title.home"),
          icon: null,
          url: "/home",
          soon: false,
        },
        {
          title: this.$t("pageHeader.title.trading"),
          icon: null,
          url: "/markets",
          soon: false,
        },
        {
          title: this.$t("pageHeader.title.dao"),
          icon: null,
          url: "/dao",
          soon: false,
        },
      ],
      localLogin: false,
      walletConnectVisible: false,
      projectId: "2c99978c56676af588fa04e38c48d815",
      mainnet: {
        chainId: 1,
        name: "Ethereum",
        currency: "ETH",
        explorerUrl: "https://etherscan.io",
        rpcUrl: "https://mainnet.infura.io/v3/eec3e26ddcd344228d7191f33bf32643",
      },
      metadata: {
        name: "DeMatch",
        description: "DeMatch",
        url: "https://portal.future-sense.cn",
        icons: ["https://avatars.mywebsite.com/"],
      },
      model: null,
      isConnected: null,
      disconnectWallet: null,
      walletProvider: null,
      isHome: false,
      web3Instance: null,
      taskId: null,
      ethersProvider: null,
    };
  },
  methods: {
    ...mapActions("ethersWallet", ["connectWalletAction", "loginBackend"]),
    ...mapMutations("ethersWallet", [
      "ACCOUNT_CHANGE",
      "CHAIN_CHANGE",
      "LOGOUTED",
      "ALL_LOGIN_SUCCESS",
      "SET_LANGUAGE"
    ]),
    goHome () {
      // console.log("this.$route.path,-------------",this.$route.path)
      if (this.$route.path === "/home") {
        return;
      }
      this.$router.push("/home");
    },
    gotoRouter (name) {
      if (this.$route.path === name) {
        return;
      }
      this.$router.push(name);
    },
    async checkAccountChange () {
      try {
        if (!this.ethersProvider) {
          return
        }
        const accounts = await this.ethersProvider.listAccounts();
        //console.log("this.userInfo.address------------",this.userInfo.address)
        if (accounts.length > 0) {
          let newAddress = accounts[0] ? accounts[0].address.toLowerCase() : "";
          if (!this.userInfo || !this.userInfo.address) {
            return
          }
          if (newAddress === this.userInfo.address?.toLowerCase()) {
            return
          }
          this.disconnectWallet()
          console.log("Account changed:", accounts[0]);
          window.location.reload();
        }
      } catch (error) {
        console.error("Error checking account change:", error);
      }
    },
    // 检查链变化
    async checkChainChange () {
      try {
        if (!this.ethersProvider) {
          return
        }
        // console.log("this.ethersProvider-----------",this.ethersProvider)
        const network = await this.ethersProvider.getNetwork();
        //console.log("this.chainId------------",this.chainId)
        let newChainId = Number(network.chainId)
        if (newChainId !== this.chainId) {
          this.CHAIN_CHANGE({ chainId: newChainId, walletProvider: this.walletProvider })
          console.log('Chain changed:', network.chainId);
        }
      } catch (error) {
        console.error('Error checking chain change:', error);
        this.ethersProvider = new ethers.BrowserProvider(this.walletProvider)
      }
    },
    metaMaskListener () {

      // 每秒钟检查一次账号和链的变更
      this.taskId = setInterval(() => {
        this.checkAccountChange();
        this.checkChainChange();
      }, 1000);
    },
    goHistory () {
      // console.log(this.$route.path)
      if (this.$route.path === "/history") {
        return;
      }
      this.walletConnectVisible = false;
      this.$router.push("history");
    },
    handleChaneLanguage (locale) {
      if (locale !== this.language) {
        this.$i18n.locale = locale;
        localStorage.setItem("language", locale);
        window.location.reload();
      }
    },
    // copy () {
    //   this.$copyText(this.userInfo.address)
    //     .then(() => {
    //       this.$toast.success("copy success");
    //     })
    //     .catch(() => {
    //       this.$toast.error("copy fail");
    //     });
    // },
    logout () {
      this.drawer = false;
      this.disconnectWallet();
    },
    showUserInfo () {
      this.model.open({ view: "Account" });
      this.drawer = false;
    },
    createWeb3Modal () {
      this.web3Instance = createWeb3Modal({
        ethersConfig: defaultConfig({ metadata: this.metadata }),
        chains: networks,
        projectId: this.projectId,
        enableAnalytics: true,
      });
      console.log("---this.web3Instance --------", this.web3Instance);
    },
    openModal () {
      if (!this.model) {
        this.initWallet();
      }
      this.model.open();
    },
    initWallet () {
      this.createWeb3Modal();
      this.model = useWeb3Modal();
      const { isConnected } = useWeb3ModalAccount();
      const { disconnect } = useDisconnect();
      this.isConnected = isConnected;
      this.disconnectWallet = disconnect;
      const { walletProvider } = useWeb3ModalProvider();
      this.walletProvider = walletProvider;
      const { setThemeMode, setThemeVariables, themeMode, themeVariables } =
        useWeb3ModalTheme();
      setThemeMode("light");

      // setThemeVariables({
      // '--w3m-color-mix': '#FF8FA6',
      // '--w3m-color-mix-strength': 40
      // })
      // console.log(address,chainId,isConnected)
    },
  },
  watch: {
    // 监听 $route 对象的变化
    $route: function (to, from) {
      // console.log("0000-----------to------", to);
      this.drawer = false;
      if (to.path === "/home") {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    },
    events: {
      handler (newValue, oldValue) {
        // console.log(JSON.stringify(newValue));
      },
      deep: true,
    },
    model: {
      handler (newValue, oldValue) {
        // console.log("model------", JSON.stringify(newValue));
      },
      deep: true,
    },
    isConnected: {
      async handler (newValue, oldValue) {
        console.log("isConnected------", newValue);
        if (newValue) {
          // console.log("this.$networks---------",this.$networks); // 输出 'value1'
          // this.walletProvider = walletProvider

          const { address, chainId } = useWeb3ModalAccount();
          // console.log(address.value, chainId.value);
          this.loginBackend({
            address: address.value,
            chainId: chainId.value,
            walletProvider: this.walletProvider,
            disconnectWallet: this.disconnectWallet,
          });
          if (!this.ethersProvider) {
            this.ethersProvider = new ethers.BrowserProvider(this.walletProvider)
          }
        } else {
          if (!localStorage.getItem("isReload")) {
            this.LOGOUTED();
            return;
          }
          localStorage.removeItem("isReload");
        }
      },
      deep: true,
    },
    'userInfo.address' (newValue, oldValue) {
      if (!newValue) {
        this.$router.push("/markets")
      }
    }
  },
  directives: {
    "click-outside": {
      bind (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          setTimeout(() => {
            // 添加延时
            if (
              typeof binding.value === "object" &&
              binding.value.method &&
              "isShown" in binding.value
            ) {
              if (
                !(el === event.target || el.contains(event.target)) &&
                binding.value.isShown
              ) {
                binding.value.method();
              }
            }
          }, 100); // 0 毫秒的延时足以将其推到下一个事件循环
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },

  computed: {
    ...mapState("ethersWallet", ["provider", "userInfo", "currentNetwork", "chainId", "language"]),
    filteredMenus () {
      return this.topMenus.filter((item) => !item.soon);
    },
  },
  beforeDestroy () {
    clearInterval(this.taskId);
    // console.log("Component is about to be destroyed!");
  },
  mounted () {
    // this.chainId = localStorage.getItem("chainId");
    let language = localStorage.getItem("language");
    this.SET_LANGUAGE({ language });

    this.localLogin = localStorage.getItem("localLogin");
    // if (this.localLogin) {
    //     this.connectWalletAction();
    // }
    if (this.localLogin) {
      localStorage.setItem("isReload", true);
      this.initWallet();
    }
    this.metaMaskListener();
    // console.log("currentNetwork-------", this.currentNetwork);
    // console.log(this.$route.path);
    if (this.$route.path === "/home") {
      this.isHome = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.out-wrapper {
  width: 100%;
  height: 100px;
  min-width: 375px;

  // top: 0px;
  display: flex; // align-content: center;
  justify-content: center;
  // flex-wrap: wrap; // border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  // position: fixed;
  padding: 0 50px;
  z-index: 1;
  // background: rgba(23,27,39,0.4);
  background: #20232a;
  // backdrop-filter: blur(5px); /* 创建磨砂效果 */
  border-bottom: 1px solid rgb(190, 188, 188);
  .nav-wrapper {
    position: relative;
    width: 100%;
    height: 100px; // margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin: 0 20px;

    .left-wrapper {
      // width: 300px;
      display: flex;
      justify-content: space-between;

      .logo-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
          width: 200px;
        }
      }

      .logo-wrapper-mobile {
        display: none;
        img {
          width: 105px;
          height: 20px;
        }
      }

      .menu-wrapper {
        display: flex;
        margin-left: 100px;
        .menu-nav {
          // width: 500px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          position: absolute;
          .menu-item {
            line-height: 100px;
            position: relative;

            a {
              // display: inline-flex;
              i {
                font-size: 20px;
                margin-right: 3px;
              }

              span {
                font-size: 16px;
                font-weight: 700;
                // color: rgba(51, 51, 51, 0.8);
                color: #fff;
              }

              &:hover {
                cursor: pointer;

                span {
                  //color: #fff;
                }
              }
            }

            .soon-wrapper {
              position: absolute;
              top: -10px;
              right: -37px;

              img {
                height: 12px;
              }
            }
          }
          .selected {
            position: relative;

            &::after {
              content: '';
              position: absolute;
              width: 40px;
              height: 6px;
              background: $global-color;
              left: 50%;
              transform: translateX(-50%);
              border-radius: 11px;
              bottom: 20px;
              animation: slide-in 0.2s ease-out forwards;
            }
          }
          .menu-item:not(:last-child) {
            margin-right: 50px;
          }

          .active {
            a {
              span {
                color: $global-color;
              }
            }
          }
        }
      }
    }

    .nav-right-box {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .switch-lang {
        height: 100%;
        position: relative;
        span {
          font-weight: 400;
          font-size: 24px;
          color: $global-color;
          line-height: 46px;
          cursor: pointer;

          &:hover {
            color: #fff;
          }
        }
        &:hover {
          .lang-panel {
            display: block;
          }
        }
        .lang-panel {
          display: none;
          position: absolute;
          width: 150px;
          height: 140px;
          background: #fefefe;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid rgba(151, 151, 151, 0.5);
          top: 80px;
          right: 0;
          z-index: 2;
          .lang-title {
            font-weight: 400;
            font-size: 14px;
            color: #7f8699;
            line-height: 20px;
            padding: 10px 20px;
          }
          .lang-item {
            padding-left: 20px;
            line-height: 32px;
            span {
              font-weight: 400;
              font-size: 14px;
              color: #3a3a45;
            }
            cursor: pointer;

            &:hover {
              background: rgba(245, 198, 82, 0.2);
              border-radius: 0px 0px 0px 0px;
              span {
                color: #ca9604;
              }
            }
            &.active {
              background: rgba(245, 198, 82, 0.2);
              border-radius: 0px 0px 0px 0px;
              span {
                color: #ca9604;
              }
            }
          }
        }
      }
      .connect-wallet {
        position: relative;
        display: flex;
        align-items: center;
        height: 40px;
        box-sizing: border-box;
        white-space: nowrap;
        margin-left: 30px;
        .connect-btn {
          box-sizing: border-box;
          width: 140px;
          height: 40px;
          background: #f5c652;
          border-radius: 10px 10px 10px 10px;

          font-weight: 400;
          font-size: 16px;
          color: #161620;
          line-height: 40px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          cursor: pointer;
          &:hover {
            background: $global-bg-color;
          }
        }

        img {
          width: 12px;
        }

        .connect-wallet-chooser {
          position: absolute;
          top: 75px;
          right: 0;
          width: 320px;
          z-index: 2000;
          padding: 26px 0 10px;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
          border-radius: 20px;
          background: #fff;

          .toolbox-header {
            height: 24px;
            margin-top: 18px;
            text-align: center;
            position: relative;
            line-height: 24px;
            margin-bottom: 22px;
            position: relative;

            span {
              color: #333;
              font-family: Inter Bold;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
            }

            .close {
              font-size: 24px;
              position: absolute;
              top: 1px;
              right: 26px;
              opacity: 0.6;
              cursor: pointer;
            }
          }

          .wallet-item {
            box-sizing: border-box;
            height: 36px;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            .wallet-item-left {
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 22px;
                height: 22px;
              }

              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                margin-left: 8px;
              }
            }

            .wallet-btn {
              box-sizing: border-box;
              width: 140px;
              height: 40px;
              background: #f5c652;
              border-radius: 10px 10px 10px 10px;

              &:hover {
                background: $global-bg-color;
              }
            }
          }
        }
      }

      .login-info {
        height: 100%;
        margin-left: 30px;
        display: flex;
        align-items: center;
        position: relative;
        .ops-item {
          padding: 8px 18px;
          border-radius: 20px;
          line-height: 24px;
          margin-right: 10px;
          cursor: pointer;
          background: #fff;
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 16px;
          color: rgba(51, 51, 51, 0.8);
          white-space: nowrap;
          span {
            font-weight: 700;
            font-size: 16px;
            color: rgba(51, 51, 51, 0.8);
            white-space: nowrap;
          }

          img {
            width: 24px;
            margin-right: 10px;
          }

          .copy-btn {
            width: 16px;
            height: 16px;
            color: rgba(211, 208, 208, 0.4);
            margin: 0 0 0 10px;
          }
        }
        &:hover {
          .login-panel {
            display: block;
          }
        }
        .login-panel {
          display: none;
          position: absolute;
          width: 150px;
          height: 210px;
          background: #fefefe;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid rgba(151, 151, 151, 0.5);
          top: 80px;
          right: 10px;
          z-index: 20;
          .login-title {
            font-weight: 400;
            font-size: 14px;
            color: #7f8699;
            line-height: 20px;
            padding: 10px 20px;
          }
          .login-item {
            padding-left: 20px;
            line-height: 32px;
            span {
              font-weight: 400;
              font-size: 14px;
              color: #3a3a45;
            }
            cursor: pointer;

            &:hover {
              background: rgba(245, 198, 82, 0.2);
              border-radius: 0px 0px 0px 0px;
              span {
                color: #ca9604;
              }
            }
            &.active {
              background: rgba(245, 198, 82, 0.2);
              border-radius: 0px 0px 0px 0px;
              span {
                color: #ca9604;
              }
            }
          }
        }
      }

      .mobile-menu-wrapper {
        display: none;
        position: relative;

        img {
          height: 30px;
          cursor: pointer;
        }

        .icon-font {
          display: flex;
          align-items: center;
          margin-left: 20px;
        }
      }
    }
    .no_visiabled {
      // visibility:hidden;
      display: none !important;
    }
  }

  @media only screen and (max-width: 1000px) {
    .nav-wrapper {
      .left-wrapper {
        .menu-wrapper,
        .logo-wrapper {
          display: none;
        }

        .logo-wrapper-mobile {
          display: block;
          cursor: pointer;
          display: flex;
          align-items: center;

          // img {
          //   height: 40px;
          // }
        }
      }

      .nav-right-box {
        .login-info {
          // .pc-show {
          // }
          display: none;
        }

        .mobile-menu-wrapper {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    & {
      padding: 0;
    }
  }
}
.menu-drawer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #f5f5f5;

  .mobile-menu-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 0px 30px;
    margin-top: 30px;

    .m-menu-item {
      width: 100%;
      padding: 15px 0;
      white-space: nowrap;
      cursor: pointer;
      border-radius: 5px;
      position: relative;
      display: flex;
      justify-content: center;

      span {
        font-size: 16px;
        color: rgba(51, 51, 51, 0.8);
        font-weight: 700;
      }

      &:hover {
        background-color: rgb(255, 255, 255, 0.5);
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #a7a4a4;
        /* 横线的颜色 */
      }
    }

    .active {
      span {
        color: $global-color;
      }
    }
  }

  .bottom-wrapper {
    display: flex;
    flex-direction: column;

    // & > * {
    //     margin-bottom: 20px;
    // }
    .ops-item {
      padding: 8px 18px;
      border-radius: 20px;
      line-height: 24px;
      margin: 10px 20px;
      cursor: pointer;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      color: rgba(51, 51, 51, 0.8);
      white-space: nowrap;
      span {
        font-weight: 700;
        font-size: 16px;
        color: rgba(51, 51, 51, 0.8);
        white-space: nowrap;
      }

      img {
        width: 24px;
        margin-right: 10px;
      }

      .copy-btn {
        width: 16px;
        height: 16px;
        color: rgba(211, 208, 208, 0.4);
        margin: 0 0 0 10px;
      }
    }

    .ops-mode {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #fff;

      img {
        width: 16px;
        height: 16px;
        color: rgba(51, 51, 51, 0.4);
      }
    }

    .logout-btn {
      background: #ff8fa6;
      box-shadow: inset 0 -6px 0 rgba(0, 0, 0, 0.16);
      padding: 8px 18px;
      border-radius: 20px;
      line-height: 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 20px;

      span {
        font-weight: 700;
        font-size: 16px;
        color: #fff;
      }

      &:active {
        box-shadow: inset 0 -8px 0 rgba(0, 0, 0, 0.16);
      }

      &:hover {
        background: $global-bg-color;
      }
    }
  }
}
.dark-bg {
  background: #171b27;
}
</style>
